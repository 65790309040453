import React, { useState, useEffect, useRef } from "react";
import {
  Collapse,
  Navbar,
  NavbarToggler,
  Nav,
  NavItem,
  NavLink,
} from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload, faPlay } from "@fortawesome/free-solid-svg-icons";
import { fetchDataByType } from "../api/api";

const Header = () => {
  const [collapsed, setCollapsed] = useState(true);
  const [navbarOpacity, setNavbarOpacity] = useState(0.85);
  const navbarRef = useRef(null);
  const [whatsappContact, setWhatsappContact] = useState("");
  const [telegramContact, setTelegramContact] = useState("");

  useEffect(() => {
    const fetchInitialData = async () => {
      const data = await fetchDataByType("kamraninkazinosu");
      if (data) {
        setWhatsappContact(data.whatsappContact);
        setTelegramContact(data.telegramContact);
      }
    };

    fetchInitialData();
  }, []);

  const toggleNavbar = () => {
    setCollapsed(!collapsed);
    setNavbarOpacity(collapsed ? 1 : 0.85);
  };

  const closeNavbar = (e) => {
    if (!collapsed && !navbarRef.current.contains(e.target)) {
      setCollapsed(true);
      setNavbarOpacity(0.85);
    }
  };

  useEffect(() => {
    const handleOutsideClick = (e) => {
      if (!collapsed && !navbarRef.current.contains(e.target)) {
        setCollapsed(true);
        setNavbarOpacity(0.85);
      }
    };

    if (!collapsed) {
      document.addEventListener("click", handleOutsideClick);
    } else {
      document.removeEventListener("click", handleOutsideClick);
    }

    return () => {
      document.removeEventListener("click", handleOutsideClick);
    };
  }, [collapsed, closeNavbar]);

  return (
    <div
      className="header-navbar"
      ref={navbarRef}
      style={{ opacity: navbarOpacity, transition: "opacity 0.4s" }}
    >
      <Navbar color="faded" light>
        <h5 className="logo">Kamranın Kazinosu</h5>
        <NavbarToggler onClick={toggleNavbar} className="me-2" />
        <Collapse isOpen={!collapsed} navbar>
          <Nav navbar>
            <NavItem>
              <NavLink
                href={`https://wa.me/${whatsappContact}?text=Salam%2C%Kamranın%20oyun%20zalında%20oynamaq%20üçün%20ödəniş%20etmək%20istəyirəm.`}
              >
                <img className="little-logos" src="/whatsapp_logo.png" alt="" />
                Whatsapp
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                href={`https://t.me/${telegramContact}?text=Salam%2C%20Kamranın%20oyun%20zalında%20oynamaq%20üçün%20ödəniş%20etmək%20istəyirəm.`}
              >
                <img className="little-logos" src="/telegram_logo.png" alt="" />
                Telegram
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink href="https://chcplay1.net/">
                <FontAwesomeIcon icon={faPlay} className="little-logos" />
                Oyna
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink href="https://app.chcplay.net/">
                <FontAwesomeIcon icon={faDownload} className="little-logos" />
                Oyunu Yüklə
              </NavLink>
            </NavItem>
          </Nav>
        </Collapse>
      </Navbar>
    </div>
  );
};

export default Header;
